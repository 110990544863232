<template>
  <MeetingRoomCreateForm />
</template>

<script>
import MeetingRoomCreateForm from "@/views/Practitioner/MeetingRoom/Create/Form";

export default {
  name: "MeetingRoom",
  components: { MeetingRoomCreateForm },
};
</script>

<style lang="scss">
:deep .v-menu__content {
  max-width: 230px;
}

.invite-meeting-room {
  .label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #33343e;
  }

  .tab_methods {
    width: 100%;
    margin-bottom: 30px;

    &__tab {
      width: 100%;
      text-transform: none;
      font-size: 14px;
    }
  }

  background-color: white;

  .header {
    margin: 72px auto 0;
    justify-content: center;
    max-width: 680px;
    @include mobile {
      margin-top: 48px;
    }

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #33343e;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subtitle-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .form {
    max-width: 464px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 6px;
    margin: 36px auto 0;

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #33343e;
    }
  }

  .step-2 {
    .header {
      .title-text {
        font-size: 28px;
        line-height: 34px;
      }

      .subtitle-text {
        margin: 0 auto;
        max-width: 500px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.32px;
      }
    }
  }
}
</style>
